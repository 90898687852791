/* Style sheet for posts web-app */

.card-area {
  display: flex;
  flex-direction: column;
  max-height: 75vh;
}

#card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 75vh;
  overflow-y: auto;
}

.card {
  margin: 2vw;
  width: 30vw;
  min-width: 250px !important;
  max-width: 500px !important;
}

#post-btn {
  display: flex;
  justify-content: center;
  margin: 2vh;
}

.modal-content {
  padding: 2.5vw;
}

.hidden {
  display: none;
}

p.error {
  color: red;
}

.modal {
  display: flex !important;
  justify-content: center !important;

}

.modal-dialog {
  display: inline-block !important;
  max-width: none !important;
  padding-top: 10vh;
}

.modal-content {
  width: 50vw !important;
  min-width: 300px !important;
}

#submit-btn {
  margin-top: 1vh;
}

#intro {
  text-align: center;
  font-size: 1em;
  margin-top: 1vh;
}
